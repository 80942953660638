import {
  MGapCol,
  SGapCol,
  XxsGapCol,
} from '@xing-com/crate-jobs-components-columns';
import { JobTeaserFacts } from '@xing-com/crate-jobs-components-job-teaser-options';
import { OptimisticPublicationDate } from '@xing-com/crate-jobs-components-optimistic-publication-date';
import { selectJobData } from '@xing-com/crate-jobs-helpers';
import { useBreakpoints } from '@xing-com/crate-jobs-hooks';
import type { JobTeaserListItemProps } from '@xing-com/crate-jobs-xinglets';
import { ProfileImage } from '@xing-com/profile-image';

import * as S from './job-teaser-list-item.styles';

export const JobTeaserListItem: React.FC<JobTeaserListItemProps> = ({
  job,
  isNew,
  url,
  routerState,
  highlight,
  matchingHighlights,
  className,
  onClick,
  onContextMenu,
  onAuxClick,
  onMouseDown,
  onMouseLeave,
  actions,
  'data-testid': dataTestId,
  headingLevel = 'h3',
  showEmploymentType,
  showRatingMarker,
  showRefreshedDate,
}) => {
  const {
    activatedAt,
    refreshedAt,
    title,
    location,
    companyName,
    companyLogos,
  } = selectJobData(job);

  const { isMediaSmallOrTiny } = useBreakpoints();

  const HeaderColComponent = isMediaSmallOrTiny ? SGapCol : MGapCol;
  const date = showRefreshedDate ? refreshedAt : activatedAt;

  return (
    <article className={className}>
      <S.ListItem
        to={url}
        state={routerState}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onAuxClick={onAuxClick}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        data-testid={dataTestId}
      >
        <ProfileImage
          type="company"
          profileName={companyName}
          size="small"
          sizeConfined="large"
          src={companyLogos.x1 ?? ''}
          // @ts-expect-error FIXME: SC6
          title={null}
        />
        <S.Content>
          <S.Header>
            <HeaderColComponent>
              {matchingHighlights?.highlight ? (
                <S.Marker variant="highlight">
                  {matchingHighlights.highlight.localization.localizationValue}
                </S.Marker>
              ) : null}
              <XxsGapCol>
                <S.Title
                  size="medium"
                  sizeWide="large"
                  forwardedAs={headingLevel}
                  data-testid="job-teaser-list-title"
                  noMargin
                >
                  {title}
                </S.Title>
                {location?.city ? (
                  <S.City size="medium" lineHeight="dense" noMargin>
                    {location.city}
                  </S.City>
                ) : null}
                <S.Company size="medium" lineHeight="dense" noMargin>
                  {companyName}
                </S.Company>
              </XxsGapCol>
            </HeaderColComponent>
            {actions}
          </S.Header>

          <S.BottomCol>
            <JobTeaserFacts
              job={job}
              matchingHighlights={matchingHighlights}
              showEmploymentType={showEmploymentType}
              showRatingMarker={showRatingMarker}
            />
            {!isMediaSmallOrTiny && highlight ? (
              <S.Highlight size="medium" lineHeight="dense" noMargin>
                <span dangerouslySetInnerHTML={{ __html: highlight }} />
              </S.Highlight>
            ) : null}
            {date ? (
              <S.Date size="small" lineHeight="dense" noMargin>
                {isNew ? <S.Notification data-testid="mini-badge" /> : null}
                <OptimisticPublicationDate date={date} />
              </S.Date>
            ) : null}
          </S.BottomCol>
        </S.Content>
      </S.ListItem>
    </article>
  );
};
