import { FormattedMessage } from 'react-intl';

import {
  getTimeDifferenceInDays,
  getTimeDifferenceInHours,
} from '@xing-com/crate-jobs-helpers';

type TranslationValues = {
  id: string;
  values?: Record<string, number>;
};

const getTranslationValues = (date: string): TranslationValues => {
  const timeDifferenceInDays = getTimeDifferenceInDays(date);
  const timeDifferenceInHours = getTimeDifferenceInHours(date);

  if (timeDifferenceInHours < 1) {
    return { id: 'JOBS_TIMESTAMP_JUST_NOW' };
  }

  if (timeDifferenceInDays < 1) {
    return {
      id: 'JOBS_TIMESTAMP_N_HOURS_AGO',
      values: { number: Math.floor(timeDifferenceInHours) },
    };
  }

  if (timeDifferenceInDays <= 2) {
    return { id: 'JOBS_TIMESTAMP_YESTERDAY' };
  }

  if (timeDifferenceInDays <= 30) {
    return {
      id: 'JOBS_TIMESTAMP_N_DAYS_AGO',
      values: { number: Math.floor(timeDifferenceInDays) },
    };
  }

  return { id: 'JOBS_TIMESTAMP_30_PLUS_DAYS_AGO' };
};

type Props = { date: string };

export const OptimisticPublicationDate: React.FC<Props> = ({ date }) => {
  const { id, values = {} } = getTranslationValues(date);

  return <FormattedMessage id={id} values={values} />;
};
