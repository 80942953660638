/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { JobTeaserVisibleJobFragment } from '@xing-com/crate-jobs-graphql';

import {
  isValidSalary,
  isValidSalaryEstimate,
  isValidSalaryRange,
} from './salary';

const NOT_BOOKMARKED = 'NOT_BOOKMARKED';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectJobData = (job: JobTeaserVisibleJobFragment) => {
  const { companyInfo, salary, activatedAt, refreshedAt, title, location } =
    job;

  const companyName = companyInfo?.companyNameOverride;
  const companyLogos = companyInfo?.company?.logos ?? {};
  const ratingAverage = companyInfo?.company?.kununuData?.ratingAverage;
  const employmentTypeValue = job.employmentType?.localizationValue;
  const bookmarkState = job.userInteractions?.bookmark?.state ?? NOT_BOOKMARKED;
  const isBookmarked = bookmarkState !== NOT_BOOKMARKED;

  const showSalary =
    isValidSalary(salary) ||
    isValidSalaryRange(salary) ||
    isValidSalaryEstimate(salary);

  return {
    salary,
    showSalary,
    activatedAt,
    refreshedAt,
    title,
    location,
    companyName,
    companyLogos,
    ratingAverage,
    employmentTypeValue,
    isBookmarked,
  };
};
