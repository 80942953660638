import type {
  JobTeaserListItemProps,
  JobTeaserListItemXinglet as JobTeaserListItemXingletInterface,
} from '@xing-com/crate-jobs-xinglets';

import { JobTeaserListItem } from './job-teaser-list-item';

export default class JobTeaserListItemXinglet
  implements JobTeaserListItemXingletInterface
{
  public getComponent(): React.ComponentType<JobTeaserListItemProps> {
    return (props): React.ReactElement => <JobTeaserListItem {...props} />;
  }
}
