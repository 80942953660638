import { FormattedMessage, FormattedNumber } from 'react-intl';

import type { Salary as SalaryVariant } from '@xing-com/crate-jobs-graphql';
import {
  isValidSalary,
  isValidSalaryRange,
  isValidSalaryEstimate,
} from '@xing-com/crate-jobs-helpers';

export const FormattedCurrency: React.FC<
  Parameters<typeof FormattedNumber>[0]
> = (props) => (
  <FormattedNumber style="currency" minimumFractionDigits={0} {...props} />
);

type Props = { salary: SalaryVariant; showXingEstimated?: boolean };

export const Salary: React.FC<Props> = ({ salary, showXingEstimated }) => {
  if (isValidSalary(salary)) {
    const { amount, currency } = salary;
    return <FormattedCurrency value={amount} currency={currency} />;
  }

  if (isValidSalaryRange(salary) || isValidSalaryEstimate(salary)) {
    const { minimum, maximum, currency, __typename } = salary;
    const showEstimated = showXingEstimated && __typename === 'SalaryEstimate';

    return (
      <>
        <FormattedCurrency value={minimum} currency={currency} />
        {' – '}
        <FormattedCurrency value={maximum} currency={currency} />
        {showEstimated ? (
          <>
            &nbsp;
            <FormattedMessage id="JOBS_SALARY_XING_ESTIMATED" />
          </>
        ) : null}
      </>
    );
  }

  return null;
};
