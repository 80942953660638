/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconArrowUpward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconArrowUpward"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13 7.414 3.293 3.293 1.414-1.414L12 3.586 6.293 9.293l1.414 1.414L11 7.414V20h2z"
    />
  </svg>
);
export default IconArrowUpward;
