import { Salary } from '@xing-com/crate-jobs-components-salary';
import type { JobKeyfactV2Fragment } from '@xing-com/crate-jobs-graphql';
import {
  isValidSalary,
  isValidSalaryRange,
  isValidSalaryEstimate,
} from '@xing-com/crate-jobs-helpers';
import { IconArrowUpward, IconTick } from '@xing-com/icons';
import type { MarkerVariant } from '@xing-com/marker';
import { Marker } from '@xing-com/marker';

type Props = {
  fact: JobKeyfactV2Fragment;
  variant: MarkerVariant;
};

/**
 * Renders a matching fact marker, which is basically the fact including
 * an icon depending on the type of the matching fact:
 *  - Arrow up for `JobMatchingHighlightsJobKeyfactSalaryV2`
 *  - Tick check for other matching
 * In case of `JobMatchingHighlightsJobKeyfactSalaryV2` with non-valid salary, nothing will be
 * rendered.
 * Additionally, no icon will be rendered if the variant is `tertiary`.
 */
export const FactMarker: React.FC<Props> = ({ fact, variant }) => {
  const withIcon = variant !== 'default';

  if (fact.__typename === 'JobMatchingHighlightsJobKeyfactSalaryV2') {
    const salary = fact.value;
    if (
      isValidSalary(salary) ||
      isValidSalaryEstimate(salary) ||
      isValidSalaryRange(salary)
    ) {
      return (
        <Marker icon={withIcon ? IconArrowUpward : undefined} variant={variant}>
          <Salary salary={salary} />
        </Marker>
      );
    }

    return null;
  }

  return (
    <Marker icon={withIcon ? IconTick : undefined} variant={variant}>
      {fact.localization.localizationValue}
    </Marker>
  );
};
